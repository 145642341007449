<template>
  <div class="flex flex-wrap mb-6 mt-9">
    <loading :show="show" :label="label"> </loading>
    <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0 bg-white shadow px-4 py-7">
      <div class="input-wrapper border-b pb-6">
        <label
          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-first-name"
        >
          Enter Meter Number
        </label>
        <PincodeInput v-model="form.meterNumber" :length="11" placeholder="0" />
      </div>

      <div class="mt-6">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              Meter Number
            </label>
            <input
              tabindex="-1"
              v-model="form.meterNumber"
              class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="number"
              readonly
            />
            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Customer's Name
            </label>
            <input
              tabindex="-1"
              v-model="form.meterName"
              class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              readonly
            />
            <p class="text-sm text-gray-500 mt-2">
              Please Confirm With the buyer if the name above owns the meter.
            </p>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col justify-end items-end text-right mt-20 border-b"
      >
        <div class="w-80 px-3 mb-6">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Amount
          </label>
          <input
            class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            v-model="form.amount"
            type="number"
            placeholder="0.00"
          />
          <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
        </div>

        <div class="w-80 px-3 mb-6">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Cashpower Amount
          </label>
          <input
            tabindex="-1"
            class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            :value="`GMD ${form.cp_amount}`"
            readonly
          />
          <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
        </div>

        <div class="w-80 px-3 mb-6">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Transaction Fee
          </label>
          <input
            tabindex="-1"
            class="appearance-none block w-full bg-gray-50 font-extrabold text-2xl text-right text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :value="`GMD ${form.fee}`"
            type="text"
            readonly
          />
          <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
        </div>
      </div>

      <div class="flex flex-col justify-end items-end text-right mt-10">
        <div class="w-80 px-3 mb-6">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            Total
          </label>
          <h1
            class="flex flex-col justify-end items-end text-right mt-4 text-4xl"
          >
            GMD :
          </h1>

          <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
        </div>

        <div class="w-80 px-3 mb-6">
          <button
            :disabled="form.meterNumber.length < 7"
            @click="consumerCheck()"
            class="bg-green-400 font-bold hover:bg-green-500 py-3 px-9 rounded-lg text-white"
          >
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 bg-white shadow">
      <div class="input-wrapper border-b py-6 flex justify-center">
        <button
          @click="print()"
          class="uppercase text-gray-700 font-bold py-3 px-4 border border-gray-400 rounded-lg hover:bg-gray-100 text-white"
        >
          <i class="bx bx-printer"></i>

          Print
        </button>
      </div>

      <div>
        <!-- {{ isComplatePrint }} -->
      </div>

      <div
        v-if="receipt"
        id="section-to-print"
        style="margin-top: 10px"
        class="flex justify-center"
      >
        <recipet :isLoading="showReceipt" :data="receipt" :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import PincodeInput from "vue-pincode-input";
import Recipet from "../../../components/Recipet.vue";
import JSPM from "../../../../public/JSPrintManager";
import loading from "vue-full-loading";

export default {
  props: ["user"],

  components: {
    Recipet,
    loading,
    PincodeInput,
  },

  data: () => ({
    applyPaymentRes: null,

    form: new Form({
      amount: null,
      meterNumber: "",
      meterName: "",
      fee: "",
      cp_amount: "",
      consumerResponse: null,
    }),
    clear: false,
    isVending: false,
    showReceipt: false,
    isComplate: "",
    receipt: null,
    confirmAmount: "",
    checkConsumer: false,
    isLoading: false,
    show: false,
    print2default: true,
    selectedPrint: null,
    label: "Loading...",
  }),

  methods: {
    vend() {
      // navigator.geolocation.getCurrentPosition(setPosition);

      Swal.fire({
        title: "confirmation?",
        html: `Meter Number: <b>${this.form.meterNumber}</b><br/>
          Customer: <b>${this.form.meterName}</b><br/>
          - <b>${this.form.consumerResponse.serviceAddress}</b><br/>
          Amount: <b>GMD ${this.form.amount}</b><br/>
          Fee: <b>GMD ${this.form.consumerResponse.fee}</b><br/>
          Cashpower Amount: <b>GMD ${
            this.form.amount - this.form.consumerResponse.fee
          }</b><br/>
          Units: <b>${this.form.consumerResponse.units}kWh </b><br/><br/><br/>
          Please re-enter amount below`,
        input: "text",
        placeholder: "Enter Amount",
        type: "warning",
        // footer: 'fooo',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, i confirm!",
      }).then((result) => {
        // console.log(result,"---REsult");
        // console.log("Amount", this.form.amount, "Confirm",this.confirmAmount)
        if (this.form.amount !== result.value) {
          // Swal.fire({
          //   icon: "error",
          //   title: "Something went wrong!",
          //   text: "Please enter correct amount",
          // });
          this.show = false;
          this.isLoading = false;
          this.clearForm();
          return false;
        }
        if (result.value) {
          this.isLoading = true;
          this.show = true;
          this.form
            .post(`/agent/vendcashpower/v2`)
            .then((response) => {
              console.log(response);
              if (response.data.status === "55") {
                Swal.fire({
                  icon: "info",
                  title: "Transaction Can't be complated!",
                  text: "Please inform your admin",
                });

                this.show = false;
                this.isLoading = false;

                return false;
              }

              this.applyPaymentRes = response.data;
              this.receipt = response.data;
            })
            .then((res) => {
              console.log(res);

              this.showReceipt = true;

              this.show = false;
              this.isLoading = false;
              this.print(this.receipt);
              this.clearForm();

              // setTimeout(() => {
              //   this.reqStsToken(this.applyPaymentRes);

              //   this.clearForm();
              // }, 6000);
            })
            .catch(function (error) {
              console.log(error);
              this.show = false;
              this.isLoading = false;
              this.clearForm();
              Swal.fire("Sorry!", `${error}`, "error");
            });
        }
      });
    },

    async reqStsToken(payload) {
      // console.log("payload", payload);
      // console.log("Apply Payment", this.applyPaymentRes);

      try {
        const response = await axios.get(
          `/agent/get_sts_token/${payload.data.transaction_id}`
        );

        console.log("RESPONSE STS", response);
        // Check if the request has token
        if (response.data.data.status == true) {
          this.showReceipt = true;
          this.receipt = response.data.data;

          this.show = false;
          this.isLoading = false;

          this.print(this.receipt);
        } else {
          // User retry
          this.isLoading = false;
          this.show = false;

          Swal.fire({
            title: "Retry?",
            text: "This transaction is stell processing click on the retry to check if it is completed",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Retry`,
            denyButtonText: `Continue`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.isLoading = true;
              this.show = true;

              this.reqStsToken(this.applyPaymentRes);
            } else if (result.isDenied) {
              // Swal.fire("Changes are not saved", "", "info");
            }
          });
        }

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        this.show = false;

        return false;
      }
    },

    clearForm() {
      this.form.amount = null;
      this.form.meterNumber = "";
      this.form.meterName = "";
      this.form.fee = "";
      this.form.cp_amount = "";
      this.form.conclearsumerResponse = null;
      // this.showReceipt = false;
      this.confirmAmount = null;
      // this.receipt = n;
      this.clear = false;
    },

    async consumerCheck() {
      // Submit the form
      this.receipt = null;
      this.showReceipt = false;

      this.isLoading = true;
      this.show = true;
      try {
        const response = await this.form.post("/agent/calculate_payment/v2");

        this.form.consumerResponse = response.data.data;
        this.form.meterName = this.form.consumerResponse.CustomerName;
        this.form.fee = this.form.consumerResponse.fee;
        this.form.cp_amount = this.form.amount - this.form.consumerResponse.fee;

        this.checkConsumer = true;

        this.isLoading = false;
        this.show = false;

        this.vend();
        return true;
      } catch (e) {
        this.isLoading = false;
        this.show = false;

        console.log("Error");
        return false;
      }
    },

    print: function (receipt) {
      console.log(
        "opertatorhis.user.data.entity_name",
        this.user.data.agent.entity_name
      );
      console.log("his.user.data.entity_name", this.user);
      console.log("recipet", receipt);
      if (this.selected_printer === "" && !this.print2default) {
        alert("You must select a printer");
        return;
      }

      var cpj = new JSPM.ClientPrintJob();

      if (this.print2default) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
      }

      var day = new Date(this.receipt.created_at);
      var dateCreated = moment().utc(day).format("Do MMM, YYYY, h:mm:ss a");

      //Set content to print...
      //Set content to print...
      //Create ESP/POS commands for sample label
      var esc = "\x1B"; //ESC byte in hex notation
      var newLine = "\x0A"; //LF byte in hex notation
      var fullCut = esc + "m";

      var cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      cmds += "CHAPMAN XPRESS"; //text to print
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += newLine + newLine;

      cmds += newLine;
      cmds += `Receipt No .: ${this.receipt.receipt}`;
      cmds += newLine + newLine;
      cmds += `Meter No .: ${this.receipt.meter_serial}`;
      cmds += newLine;

      cmds += esc + "!" + "\x22";
      cmds += `${this.receipt.customer_name}`;
      cmds += esc + "!" + "\x00";

      cmds += newLine;
      cmds += `${this.receipt.meter_serial}`;
      cmds += newLine + newLine;
      cmds += "------------------ Token Number ---------------";
      cmds += newLine;
      cmds += esc + "1" + "\x01  ";

      // cmds += '';
      cmds += newLine;
      cmds += "********************************************";
      cmds += newLine + newLine;
      cmds += esc + "!" + "\x30"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

      // List all tokens
      if (this.receipt.list_tokens && this.receipt.list_tokens.length > 0) {
        this.receipt.list_tokens.forEach((token) => {
          cmds += `${this.formatToken(token)}\n`; // Add each token on a new line
        });
      }

      cmds += newLine + newLine;
      cmds += esc + "!" + "\x00";

      cmds += "********************************************";
      cmds += newLine + newLine;

      cmds += `Amount Paid .:       GMD ${this.receipt.total_payment}`;
      cmds += newLine;
      cmds += `Fee .:               GMD ${this.receipt.fee}`;
      cmds += newLine;
      cmds += `Cashpower Amount .:  GMD ${this.receipt.amount}`;
      cmds += newLine;
      cmds += `Total Units .:       ${this.receipt.units}kWh`;
      cmds += newLine;

      cmds += `Payment Date .:      ${dateCreated}`;

      cmds += newLine + newLine;
      cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += `Operator: ${this.user.data.agent.entity_name}`;
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += "Use Energy Wisely!";
      cmds += newLine;

      // cmds += esc + '!' + '\x38';

      cmds += newLine;
      cmds += `Phone: +220 438 3223 - 307 4455 - `;
      cmds += newLine + newLine;
      cmds += newLine + newLine;

      cmds += fullCut;

      cpj.printerCommands = cmds;
      //Send print job to printer!
      cpj.sendToClient();

      // this.receipt = null;
    },

    // print: function (receipt) {
    //   console.log('opertatorhis.user.data.entity_name', this.user.data.agent.entity_name);
    //   console.log('his.user.data.entity_name', this.user);
    //   console.log("recipet", receipt);
    //   if (this.selected_printer === "" && !this.print2default) {
    //     alert("You must select a printer");
    //     return;
    //   }

    //   var cpj = new JSPM.ClientPrintJob();

    //   if (this.print2default) {
    //     cpj.clientPrinter = new JSPM.DefaultPrinter();
    //   } else {
    //     cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
    //   }

    //   var day = new Date(this.receipt.created_at);
    //   var dateCreated = moment().utc(day).format("Do MMM, YYYY, h:mm:ss a");

    //   //Set content to print...
    //   //Set content to print...
    //   //Create ESP/POS commands for sample label
    //   var esc = "\x1B"; //ESC byte in hex notation
    //   var newLine = "\x0A"; //LF byte in hex notation
    //   var fullCut = esc + "m";

    //   var cmds = esc + "@"; //Initializes the printer (ESC @)
    //   cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
    //   cmds += "CHAPMAN XPRESS"; //text to print
    //   cmds += newLine;
    //   cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

    //   cmds += newLine + newLine;

    //   cmds += newLine;
    //   cmds += `Receipt No .: ${this.receipt.receipt}`;
    //   cmds += newLine + newLine;
    //   cmds += `Meter No .: ${this.receipt.meter_serial}`;
    //   cmds += newLine;

    //   cmds += esc + "!" + "\x22";
    //   cmds += `${this.receipt.customer_name}`;
    //   cmds += esc + "!" + "\x00";

    //   cmds += newLine;
    //   cmds += `${this.receipt.meter_serial}`;
    //   cmds += newLine + newLine;
    //   cmds += "------------------ Token Number ---------------";
    //   cmds += newLine;
    //   cmds += esc + "1" + "\x01  ";

    //   // cmds += '';
    //   cmds += newLine;
    //   cmds += "********************************************";
    //   cmds += newLine + newLine;
    //   cmds += esc + "!" + "\x30"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

    //   cmds += `${this.formatToken(this.receipt.list_tokens[0])}`;
    //   cmds += newLine + newLine;
    //   cmds += esc + "!" + "\x00";

    //   cmds += "********************************************";
    //   cmds += newLine + newLine;

    //   cmds += `Amount Paid .:       GMD ${this.receipt.total_payment}`;
    //   cmds += newLine;
    //   cmds += `Fee .:               GMD ${this.receipt.fee}`;
    //   cmds += newLine;
    //   cmds += `Cashpower Amount .:  GMD ${this.receipt.amount}`;
    //   cmds += newLine;
    //   cmds += `Total Units .:       ${this.receipt.units}kWh`;
    //   cmds += newLine;

    //   cmds += `Payment Date .:      ${dateCreated}`;

    //   cmds += newLine + newLine;
    //   cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
    //   cmds += `Operator: ${this.user.data.agent.entity_name}`;
    //   cmds += newLine;
    //   cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

    //   cmds += "Use Energy Wisely!";
    //   cmds += newLine;

    //   // cmds += esc + '!' + '\x38';

    //   cmds += newLine;
    //   cmds += `Phone: +220 438 3223 - 307 4455 - `;
    //   cmds += newLine + newLine;
    //   cmds += newLine + newLine;

    //   cmds += fullCut;

    //   cpj.printerCommands = cmds;
    //   //Send print job to printer!
    //   cpj.sendToClient();

    //   // this.receipt = null;
    // },

    formatToken(token) {
      return token.match(/\d{4}(?=\d{2,3})|\d+/g).join("-");
    },

    onInit: function () {
      console.log("IN INIT", JSPM);
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },

    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log(printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },
  },

  mounted: function () {
    this.onInit();
  },
};
</script>
